/*****************************
  Button
*****************************/
/* btn */
.btn {
	z-index: 1;
	position: relative;
	font-size: 16px;
	font-weight: 500;
	padding: 13px 40px;
	overflow: hidden;
	border-radius: $border-radius-md;
	border: none;
	transition: all 0.3s ease-in-out;
	font-family: $font-hedding;
	color: $primary;
	background-color: $white;

	&:focus {
		box-shadow: none;
		outline: none;
		background-color: $primary;
		color:$white;
	}

	&:active {
		box-shadow: none;
		outline: none;
		color:$white;
		background-color: $primary;
	}

	i{
		padding-right: 10px;
	}

	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 100%;
		top: 0;
		right: 0;
		z-index: -1;
		background-color: $primary;
		transition: all 0.4s ease;
		border-radius: $border-radius-md;
	}
	&:hover {
		color: $white;
		background-color:$white;
		box-shadow: none;
		outline: none;
		&:after {
			left: 0;
			width: 100%;
		}
	}
}

.btn.btn-sm {
	padding: 8px 20px;
	font-size: 14px;
}

.btn.btn-md {
	padding: 10px 30px;
	font-size: 14px;
}

.btn.btn-lg {
	padding: 14px 40px;
	font-size: 18px;
}

.btn.btn-xl {
	padding: 18px 45px;
	font-size: 18px;
}

.btn-link {
	font-family: $font-hedding;
	color: $primary;
	transition: all 0.3s ease-in-out;
	font-size: 18px;
	i{
		padding-left: 5px;
	}

	&:after {
		display: none;
		content: inherit;
	}

	&:focus{
		color:$primary;
		text-decoration: underline !important;
	}

	&:hover {
		color: $primary;
	}
}

.btn.btn-link{
	background-color:inherit !important;
	color: $primary;
	padding: 0px;
	border-radius: 0px;
}

button {
	outline: medium none !important;
	color: $primary;
}

.show {
	>.btn.dropdown-toggle {
		&:focus {
			box-shadow: none;
			outline: none;
			color: $white;
		}
	}

	>.btn-primary.dropdown-toggle {
		background: $primary;
		border-color: $primary;
	}
}


.btn.btn-secondary {
	background-color: $secondary;
	color: $white;
	&:focus {
		color: $white;
		background-color: $primary;
	}

	&:active {
		color: $white;
		background-color: $primary;
	}

	&:after {
		background-color: $primary;
	}
	&:hover {
		color: $white;
		background-color:$secondary;
	}
}

.btn.btn-dark {
	background-color: $dark;
	color: $white;
	&:focus {
		color: $white;
		background-color: $primary;
	}

	&:active {
		color: $white;
		background-color: $primary;
	}

	&:after {
		background-color: $primary;
	}
	&:hover {
		color: $white;
		background-color:$dark;
	}
}

.bg-primary{
	.btn{
		&:hover {
			color: $white;
			background-color:$white;
		}

		&:after {
			background-color: $secondary;
		}
	}
	.btn.btn-link{
		color: $secondary;
		&:hover{
			color:$secondary;
		}
	}
	.btn.btn-secondary {
 	  background-color: $secondary;
		color: $white;
		&:hover {
			color: $secondary;
			background-color:$secondary;
		}
		&:focus {
			color: $secondary;
			background-color: $white;
		}

		&:active {
			color: $secondary;
			background-color: $white;
		}

		&:after {
			background-color: $white;
		}
  }
}


.bg-secondary{
	.btn.btn-primary {
 	  background-color: $primary;
		color: $dark;
		&:hover {
			color: $dark;
			background-color:$primary;
		}
		&:focus {
			color: $dark;
			background-color: $white;
		}

		&:active {
			color: $dark;
			background-color: $white;
		}

		&:after {
			background-color: $white;
			transition: all 0.4s ease;
			border-radius: $border-radius-md;
		}
  }
  .btn.btn-link{
		color: $primary;

		&:hover{
			color:$primary;
		}
	}
}

.btn.btn-light{
	color: $dark;
}


.btn.btn-primary {
	background-color: $primary;
	color: $dark;
	&:hover {
		color: $dark;
		background-color:$primary;
	}
	&:focus {
		color: $dark;
		background-color: $primary;
	}

	&:active {
		color: $dark;
		background-color: $primary;
	}

	&:after {
		background-color: $white;
	}
}

.btn-check:active+.btn-primary:focus, 
.btn-check:checked+.btn-primary:focus, 
.btn-primary.active:focus, 
.btn-primary:active:focus, 
.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn.btn-outline{
	border: 2px solid $white;
	color: $white;
	background-color: transparent;

	&:hover {
		background-color: transparent;
		color: $white;
		border: 2px solid $primary;
	}

	&:focus {
		background-color: transparent;
		color: $white;
		border: 2px solid $white;
	}

	&:active {
		background-color: transparent;
		color: $white;
		border: 2px solid $white;
	}

	&:active {
		&:focus {
			box-shadow: inherit;
		}
	}

}

.btn.btn-outline.primary {
	border: 2px solid $primary;
	color: $primary;

	&:hover {
		
		color: $primary;
		border: 2px solid $white;
	}

	&:focus {
		color: $primary;
		border: 2px solid $primary;
	}

	&:active {
		color: $primary;
		border: 2px solid $primary;
	}

	&:active {
		&:focus {
			box-shadow: inherit;
		}
	}

	&:after {
		background-color: $white;
		transition: all 0.4s ease;
		border-radius: 2px;
	}
	
}

.btn.btn-outline.secondary {
	border: 2px solid $secondary;
	color: $secondary;

	&:hover {
		color: $secondary;
		border: 2px solid $white;
	}

	&:focus {
		color: $secondary;
		border: 2px solid $secondary;
	}

	&:active {
		color: $secondary;
		border: 2px solid $secondary;
	}

	&:active {
		&:focus {
			box-shadow: inherit;
		}
	}

	&:after {
		background-color: $white;
		transition: all 0.4s ease;
		border-radius: 2px;
	}
	
}

.btn-sm{
 &:after{
 	border-radius:$border-radius-md !important;
 }
}


.btn.btn-outline-secondary:not(:disabled):not(.disabled):active {
	background: transparent;
	color: $white;
	border: 2px solid $primary;
}

.video-btn{
  display: flex;
  width: 66px;
  height: 66px;
  z-index: 9;
  text-align: center;
  background: $primary;
  border-radius: $border-radius-md;
  align-items: center;
  align-self: center;
  justify-content: center;
  position: relative;
  color: $dark;
  &:hover{
  	color: $white;
  }
  &:focus{
  	color: $white;
  }
}
.btn-animation:before {
	content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    display: block;
    width: 66px;
    height: 66px;
    background: $primary;
    border-radius: $border-radius-md;
    animation: pulse-border 1500ms ease-out infinite;
}

/* keyframes */
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

@media (max-width:575px) {
	.btn{
	   padding: 12px 25px;
	}
}