/*****************************
  Header
*****************************/
.header{
	z-index: 999;
	.navbar {
		padding: 0;
		.navbar-brand{
			padding: 34px 0;
			font-size: 0;
		}
		.navbar-nav {
			.nav-item {
				margin-right: 34px;
				position: relative;
				&:last-child{
					margin-right:0;
				}
			}

			.nav-link {
				color: $white;
				font-family: $font-hedding;
				font-weight: 500;
				font-size: 18px;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				transition: all 0.3s ease-in-out;
				padding: 30px 5px;
				outline: none;

				&:hover {
					color: $primary;
				}

				i {
					margin-left: 8px;
				}

			}

			li.active {
				> a {
					color: $primary;
				}

			}

			.dropdown-menu {
				.nav-title{
					font-size: 22px;
					font-family: $font-hedding;
					display: inline-block;
					margin-bottom: 10px;
				}
				li.active {
					> a {
						background: rgba($primary, .07);
						color: $primary;
						padding-left: 10px;
						border-radius: 0;
					}

				}

				li:hover {
					> a {
						background: rgba($primary, .07);
						color: $primary;
						padding-left: 10px;
						border-radius: 0;
					}
				}

				li{
					.dropdown-item{
						font-family: $font-hedding;
						outline: none;
						transition: all 0.3s ease-in-out;
						&:hover{
							background: rgba($primary, .07);
							color: $primary;
							padding-left: 10px;
							border-radius: 0;
						}
					}

					.dropdown-submenu{
						font-family: $font-hedding;
						outline: none;
						transition: all 0.3s ease-in-out;
						&:hover{
							background: rgba($primary, .07);
							color: $primary;
							padding-left: 10px;
							border-radius: 0;
						}
					}
				}

			}

		}
	}
}


/*Header Style 01*/
.header.header-style-01 {
	position: absolute;
	width: 100%;
	.add-listing {
		ul{
			li{
				
				a{
					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}
}

.header.header-style-01.is-sticky{
	.add-listing {
		ul{
			li{
				
				a{
					&:hover {
						color: $primary;
					}
				}
			}
		}
	}
}

/*Header Style 02*/
.header.header-style-02{
	position: absolute;
  width: 100%;
 .add-listing.login-listing{
   .dropdown {
   	.dropdown-toggle{
   		&:hover{
   			color: $primary;
   		}
   	}
   }
   .login {
		a{
			&:hover{
	 			color: $primary;
	 		}
		}
   }
 }
}

.add-listing {
	display: flex;
	align-items: center;

	ul{
		display: flex;
		align-items: center;
		padding: 0;
		margin: 0;
		li{
			list-style-type: none;
			margin-left: 30px;
			a{
				color: $white;
				font-size: 16px;
				&:hover {
					color: $primary;
				}
			}

			.notifications{
				position: relative;
				.count{
					position: absolute;
			    top: -5px;
			    right: -10px;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    flex: 0 0 15px;
			    width: 15px;
			    height: 15px;
			    background-color:$white;
			    border-radius: 100%;
			    color: $secondary;
			    font-size: 10px;
				}

				&:after{
					content: inherit;
				}
			}

			&:first-child{
				margin-left: 0px;
			}
		}
	}
}

/*notifications-list*/
.header{
	.navbar{
		.add-listing {
			ul{
				.dropdown{
					.dropdown-menu.notifications-menu{
						min-width: 350px;
						padding: 0;
						left: inherit;
						right: 0;
						top: 50px;
						background-color:$dark;
						.notifications-title{
							font-size: 14px;
							font-family:$font-base;
							border-bottom: 1px solid rgba($white,0.05);
							padding: 20px;
						}
						.notifications-list{
							margin-bottom: 0px;
							margin-left: 0px;
							padding: 20px;
							.notifications-info{
								display: flex;
								position: relative;
								align-items: center;
								.notifications-author{
									flex: 0 0 40px;
									width: 40px;
									height: 40px;
									margin-right: 20px;
									border-radius: $border-radius-full;
									img{
										border-radius: $border-radius-full;
									}
								}
								.notifications-details{
									margin-right: 10px;
									p{
										font-size: 14px;
										color: $white;
										line-height: 20px;
										margin-bottom:5px;
										text-transform: capitalize;
										transition: all 0.3s ease-in-out;
										display: inline-block;
							      overflow: hidden;
							      text-overflow: ellipsis;
							      display: -webkit-box;
							      -webkit-line-clamp: 2;
							      -webkit-box-orient: vertical;

										&:hover{
											color: $primary;
										}
									}
								}

								.movie-img{
									flex:0 0 40px;
									width: 40px;
									height: 40px;
									margin-left: 20px;
								}
							}

							&:hover{
								background-color: $secondary;
							}
							&:last-child{
								margin-bottom: 0px;
							}
						}
					}
				}
			}
		}
	}
}

.header.landing-header{
	.add-listing.login-listing {
		a{
			&:hover{
				color: $primary;
			}
		}
	}
}

/*megamenu*/
.megamenu {
	padding: 20px 20px;
	width: 100%;
	>div {
		>li {
			>ul {
				padding: 0;
				margin: 0;
				>li {
					list-style: none;
					>a {
						display: block;
						padding: 3px 20px;
						clear: both;
						font-weight: normal;
						line-height: 1.428571429;
						color: $dark;
						white-space: normal;
						&:hover {
							text-decoration: none;
							color: $dark;
							background-color: $dark;
						}
						&:focus {
							text-decoration: none;
							color: $dark;
							background-color: $dark;
						}
					}
				}
			}
		}
	}
	.card {
		outline: none;
		&:hover {
			outline: 1px solid $black;
		}
		&:focus {
			outline: 1px solid $black;
		}
	}
}
.megamenu.disabled {
	>a {
		color: $dark;
		&:hover {
			color: $dark;
			text-decoration: none;
			background-color: transparent;
			background-image: none;
			cursor: not-allowed;
		}
		&:focus {
			color: $dark;
			text-decoration: none;
			background-color: transparent;
			background-image: none;
			cursor: not-allowed;
		}
	}
}
.megamenu.dropdown-header {
	color: $primary;
	font-size: 18px;
}
.dropdown-submenu {
	position: relative;
	padding: 7px 0 7px 0;
	display: block;
	color: $secondary;
}


/*Header transparent*/
.header.header-transparent {
	position: absolute;
	width: 100%;
	background: none;
}

/*Header Sticky*/
.header.is-sticky {
  position: fixed !important;
  width: 100%;
  background: $secondary;
  box-shadow: $box-shadow;
  top: 0;
  z-index: 999;
  transition: 0.5s ease-in-out;
  animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;

  .navbar {
  	.navbar-brand{
  		padding: 25px 0;
  	}
  	.navbar-nav{
  		.nav-link{
  			padding: 20px 5px;
  		}
  	} 
  }
}


@keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }

}

/*header-search*/
.header-search{
	.search-form{
		position: relative;
		width: 300px;
		.form-control{
			background-color: inherit;
			border-color:$white;
			color: $white;
			height: 44px;
			padding-right: 120px;
			font-family: $font-hedding;

		}
		.search-btn{
			position: absolute;
			top: 1px;
			right: 1px;
			border-radius: $border-radius-md;
			line-height: normal;
			background-color: $white;
			color: $primary;
			font-size: 18px;
			padding:8px 12px;
			font-family: $font-hedding;
			display: flex;
			align-items: center;

			svg{
				height: 20px;
				width: 20px;
				margin-right: 10px;
			}

		}
	}
}

.add-listing.login-listing{
	 	.login{
	 		a{
		 		font-size: 18px;
		 		color: $white;
		 		&:hover{
		 			color: $dark;
		 		}
		 		i{
		 			margin-right: 10px;
		 		 }
	  	 }
	   }
	   .dropdown{
      .dropdown-toggle{
      	font-size: 18px;
      	color: $white;
      	&:hover{
      		color: $dark;
      	}
      	&:after{
         display: none;
      	}
      	i{
      		font-size: 15px;
      	}
      }
      .dropdown-menu{
      	min-width: 9rem;
      	padding: 10px;
      	background: $white;
      	margin-top: 10px !important;
      	.dropdown-item{
      		padding: 7px 0;
      		font-size: 14px;
      		color: $dark;
      		.country-flag{
      			margin-right: 10px;
      			width: 25px;
      		}
      		&:hover{
      			padding: 7px;
      			background-color: rgba($primary, 0.10);

      		}
      	}
      }
	   }
	 }
.landing-header.is-sticky{
 .add-listing{
  .dropdown {
  	.dropdown-toggle{
     &:hover{
     	color: $primary;
     }
  	}
  }
   .login {
   	a{
   		&:hover{
     	color: $primary;
     }
   	}
   }
 }
}


@media (min-width:992px) {

	.header {
		.navbar {
			.navbar-nav{
				.dropdown-menu.dropdown-menu-md{
					min-width: 420px;
				}
			}
			.dropdown-menu {
				margin: 0px;
				min-width: 200px;
				font-size: 16px;
				border-radius:0;
				padding: 20px;
				border-color: transparent;
				left: 0;
				transform: translate3d(-10px, 15px, 0);
				transition: all 0.3s ease-in-out;
				transition-property: opacity, visibility, transform;
				transform-origin: top center;
				box-shadow: 0px 5px 10px rgba($black, 0.1);
				visibility: hidden;
				opacity: 0;
				display: block !important;

				.dropdown-submenu {
					.dropdown-menu {
						left: 100%;
						right: auto;
						transform: translate3d(0, 10px, 0);
					}
					.dropdown-menu.left-side {
						right: 100%;
						left: auto;
						transform: translate3d(-15px, 10px, 0);
					}
				}
			}

			.dropdown-menu.megamenu {
				left: -170px;
			}
			.dropdown-menu.megamenu.megamenu-fullwidth{
				width: calc(100% - 20px);
    		left: 20px;
			}
			.dropdown {
				>.dropdown-menu {
					top: 100%;
					margin-top: 0px;
				}
				>.dropdown-menu.megamenu {
					top: 100%;
				}
				&:hover {
					>.dropdown-menu {
						transform: translate3d(-10px, 0px, 0);
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
	}
	.navbar-nav {
		.dropdown-menu.dropdown-menu-lg {
			min-width: 630px;
		}
		.dropdown-menu.dropdown-menu-md {
			min-width: 450px;
		}
		.mega-menu {
			position: static;
			
		}
		li {
			&:hover {
				>ul.dropdown-menu {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
	.dropdown-submenu {
		position: relative;
		padding: 7px 0 7px 0;
		display: block;
		color: $secondary;
		text-decoration: none;
		transition: all 0.3s ease-in-out;
		&:hover{
			background: rgba($primary, 0.07);
	    color: $primary;
	    padding-left: 10px;
	    border-radius: 6px;
		}
		>.dropdown-menu {
			top: 0;
			left: 100%;
			margin-top: -6px;
		}
	}
	.dropdown-menu {
		>li {
			>a {
				&:hover {
					&:after {
						text-decoration: underline;
						transform: rotate(-90deg);
					}
				}
			}
		}
		.dropdown-item {
			transition: all 0.3s ease-in-out;
			padding: 7px 0 7px 0;
			&:focus{
			background-color:transparent;
			}
		}
	}
}

@media (max-width:1199px) {

	.header-search {
		.search-form{
			width: 250px;
		}
	}

	.header.header-style-02{
		.navbar{
			.navbar-nav{
				.nav-item{
					margin-right: 15px;
				}
			}
		} 
		.add-listing{
			ul{
				li {
					margin-left: 15px;
				}
			} 
		}
	}

}


@media (max-width:991px) {
	.header {
		.navbar-collapse {
			position: absolute;
			top: 100%;
			z-index: 999;
			background: $dark;
			width: 100%;
	    max-height: 350px;
	    overflow: auto;
			left: 0;
			margin-top: -1px;
			margin-left: 0px;
		}

		.navbar {
			.navbar-brand{
				padding: 25px 0px;
			}
			.navbar-nav {
				align-items: initial;

				.nav-link {
					color: $white;
					padding: 10px 20px;

					i {
						position: absolute;
						right: 20px;
					}

				}

				.nav-item {
					margin-right: 0;
				}

        .dropdown.mega-menu {
        	.megamenu{
            width: 95%;
            margin: 0 auto;
        	}
        }

			}

			.dropdown-menu {
				margin: 0px 15px;
				font-size: 16px;
				border-radius: 0px;
				border: none;
				-webkit-box-shadow: none;
				box-shadow: none;
				background: $secondary;
				padding: 15px;
				border-radius: 0;
				.dropdown-item{
          padding: 10px 0px;
          color: $white;
				}

				.nav-title{
					color: $white;
				}

				.dropdown-submenu{
				 	color: $white;
				 	font-size: 16px;
				 	text-decoration: none;
				 	&:hover{
						background: rgba($primary, 0.07);
				    color: $primary;
				    padding-left: 10px;
				    border-radius: 6px;
					}
				}
			}

			.nav-title {
				margin-top: 0px;
			}

			.navbar-toggler {
				position: absolute;
				right: 12px;
				box-shadow: none;
				transition: 0.5s ease-in-out;
				background: $primary;
				color: $white;
				padding: 15px;
				border-radius: 4px;
				display: flex;
				height: 40px;
				width: 40px;
				justify-content: center;
				align-items: center;
			}

			.add-listing {
				padding-right: 70px;

				.menu-btn {
					display: none;
				}

			}

		}

	}

	.header.is-sticky {
		.navbar {
			.navbar-nav{
				.nav-link{
					padding: 10px 20px;
				}
			}
		}
	} 
	.landing-header{
	  .add-listing {
		  padding-right: 0px !important;
		}
	}


	.header {
		.navbar {
			.add-listing {
				ul{
					.dropdown {
						.dropdown-menu.notifications-menu{
							top: 50px;
						}
					}
				}
			}
		}
	} 


}

@media (max-width:575px) {

.header{
		.navbar {
			.navbar-brand {
		    img{
		    	height: 20px;
		    }
			}
			.add-listing{
				ul{
					.dropdown {
						.dropdown-menu.notifications-menu{
							min-width: 270px;
							right: -85px;
							top: 47px;
						}
					}
				}
			}

		}
	}

	.add-listing{
		ul{
			li{
				margin-left:20px;
			}
		} 
	}
	 .landing-header{
	  .add-listing {
      .login {
      	a{
      		font-size: 16px;
      	}
      }
      .dropdown {
      	.dropdown-toggle{
      		font-size: 16px;
      	}
      }
	  }
	}
	.header.header-style-01 {
		.add-listing {
			ul {
				li {
					a{
						&:hover{
							color: $primary;
						}
					}
				}
			}
		}
	}



}

		
@media (max-width:479px) {
	.header-style-02{
		.navbar{
			.add-listing{
				padding-right: 70px;
				.login{
					a{
						font-size: 0;
						i{
							font-size: 18px;
						}
					}
				}
			}
		} 
	}
}