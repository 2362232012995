/*****************************
  Team
*****************************/
.team{
	 position: relative;
	.team-img{
	  overflow: hidden;
	  position: relative;
	 img{
		  transform: scale(1);
	    transition: transform 7s cubic-bezier(.25, .46, .45, .94);
	    min-height: 100%;
	    object-fit: cover;
	 }
	 .team-info{
	 	position: absolute;
	 	bottom: 20px;
	 	left: 20px;
	 	right: 20px;
	 	text-align: center;
	 	padding: 15px;
	 	z-index: 9;
	 	transition: all 0.3s ease-in-out;
	 	.title{
	 		margin-bottom: 10px;
      &:hover{
		 		a{
		 			color: $primary;
		 		}
      }
	 	}
	 	a.team-designation{
	 		color: $white;
	 		display: inline-block;
	 		margin-bottom: 10px;
      &:hover{
		 		color: $primary;
      }
	 	}

	 	ul{
	 		 margin-bottom: 0;
	 		li{
	 			display: inline-block;
	 			margin-right: 10px;
	 			a{
	 				color: $white;
	 				&:hover{
	 				 color: $primary;
	 				}
	 			}
	 		}
	 	}
	  }
   }
  &:hover{
  	.team-img{
  		img{
				transform: scale(1.1);
			}
			.team-info{
        background-color: $dark;
			}
  	}
  }
  &:before{
		left: 0;
		bottom: 0;
		width: 100%;
		height: 40%;
		content: "";
		z-index: 1;
		margin: 0 auto;
		position: absolute;
		transition: all .3s ease-in-out;
		background: linear-gradient(180deg, rgba(0,0,0,0) 15%, rgba(0,0,0,1) 140%);
  }
}

@media (max-width:991px) {
	.team {
		.team-img {
			.team-info {
				a.team-designation{
					font-size: 14px;
				}
			}
		}
	}
}
