/*****************************
    Typography
*****************************/
body {
	font-family: $font-base;
	font-style: normal;
	font-size: 16px;
	line-height: 1.5;
	color: $body-color;
	overflow-x: hidden;
}

a {
	outline: medium none !important;
	color: $primary;
	transition: all 0.3s ease-in-out;
	text-decoration: none !important;

	&:focus {
		color: $primary;
		text-decoration: none !important;
	}

	&:hover {
		color: $primary;
		text-decoration: none !important;
	}

}

input {
	outline: medium none !important;
	color: $primary;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-hedding;
	font-weight: normal;
	color: $body-color;
	margin-top: 0px;
	font-weight: 400;
	margin-bottom: 0;

	a {
		color: inherit;
	}

}

label {
	font-weight: normal;
}

h1 {
	font-size: 60px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
}

h2 {
	font-size: 34px;
	font-weight: 600;
}

h3 {
	font-size: 30px;
	font-weight: 600;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 22px;
}

h6 {
	font-size: 20px;
}

p {
	font-weight: normal;
	line-height: 1.5;
}

*::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}
.fw-medium{
	font-weight: 500;
}
.fw-600{
	font-weight: 600;
}


/* form-control */
.form-control, .form-select {
	border: 1px solid $border-color;
	height: 60px;
	padding: 10px 20px;
	box-shadow: none;
	font-size: 14px;
	font-weight: 500;
	color: $dark;
	background-clip: inherit !important;

	&:focus {
		box-shadow: none;
		border-color: $primary;
	}
}

.avatar-lg{
    min-width: 80px;
    width: 80px;
}


.border-right-0{ border-right: inherit !important; }
.border-right{ border-right: 1px solid $border-color; }

.border-left-0{ border-left: inherit !important; }
.border-left{ border-left: 1px solid $border-color; }

.form-control::-moz-placeholder {
	color: $body-color;
}

.form-control::-ms-input-placeholder {
	color: $body-color;
}

.form-control::-webkit-input-placeholder {
	color: $body-color;
}

textarea.form-control {
	height: auto;
}

.input-group.date {
	.input-group-text {
    height: 100%;
    border-radius: inherit;
    border: none;
    background-color: $secondary;
   i{
   	color:$primary;
   }
	}
}

/* input-group-prepend */
.input-group-prepend,
.input-group-append {
	.input-group-text {
		width: 50px;
		background: transparent;
		border-color: $border-color;
		box-shadow: none;
		justify-content: center;
	}

}


/* file-upload */
.input-group.file-upload{
	height: 50px;

	.form-control{
		height: 50px;
    color: $body-color;
    border-color: $border-color;
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
	}
}

@media (max-width:991px) {

	h2 {
		font-size: 32px;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 22px;
	}

	h5 {
		font-size: 20px;
	}

}

@media (max-width:767px) {

	h2 {
		font-size: 30px;
	}

	h3 {
		font-size: 26px;
	}

	h4 {
		font-size: 24px;
	}

	h5 {
		font-size: 20px;
	}

}


@media (max-width:575px) {

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 22px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-size: 18px;
	}

}