:root {
  --black: rgba(10, 10, 10, 1);
  --background: rgba(18, 18, 18, 1);
  --surface: rgba(30, 30, 30, 1);
  --surface-front: rgba(45, 45, 45, 1);
  --primary: rgba(176, 42, 55, 1);
  --secondary: rgba(176, 42, 55, 0.5);
  --warningRed: rgba(176, 42, 55, 0.5);
  --warningRedHover: rgba(176, 42, 55, 0.8);
  --on-background: rgba(225, 225, 225, 1);
  --on-surface: rgba(225, 225, 225, 1);
  --on-surface-disabled: rgba(225, 225, 225, 0.3);
  --on-surface-faded: rgba(168, 168, 168, 1);
  --on-primary: rgba(225, 225, 225, 1);
  --on-secondary: rgba(225, 225, 225, 1);
  --accent-dark: #b02a37;
  --accent-regular: #bb2d3b;
  --accent-light: #dc3545;
  --accent-light-trans: rgba(220, 53, 69, 0.4);
  --xxonline-green: rgba(0, 255, 64, 0.4);
  --online-red: rgba(255, 0, 0, 0.4);
  --zred: rgba(244, 67, 54, 1);
  --red: rgba(187, 22, 10, 1);
  --online-green: rgba(22, 187, 10, 1);
  --red-dimmed: rgba(215, 148, 155, 1);
  --yello-gold: rgb(255, 218, 135);
  --player-overlay-bg: rgba(0, 0, 0, 0.5);
  --template-primary: rgba(246, 190, 0, 1);
  /*BOOTSTRAP OVERRIDE*/

  --modal-bg: rgba(18, 18, 18, 1);
  --on-modal-bg: rgba(225, 225, 225, 0.6);
  --modal-border: rgba(225, 225, 225, 0.2);
  --container-border: rgba(225, 225, 225, 0.3);
  --button-border: rgba(225, 225, 225, 0.4);
  --form-control-bg: rgba(6, 6, 6, 0.8);
  --form-control-color: rgba(190, 190, 190, 1);
}

@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@font-face {
  font-style: normal;
  font-family: Roboto;
  font-weight: 300;
  src: local('Rooto'), url('./static/fonts/Roboto-Light.ttf') format("truetype");
}

@font-face {
  font-style: normal;
  font-family: Roboto;
  font-weight: 400;
  src: local('Rooto'), url('./static/fonts/Roboto-Regular.ttf') format("truetype");
}

@font-face {
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  src: local('Rooto'), url('./static/fonts/Roboto-Medium.ttf') format("truetype");
}

@font-face {
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  src: local('Rooto'), url('./static/fonts/Roboto-Bold.ttf') format("truetype");
}



.App {
  text-align: center;
}

.masterContainer {}

.bMenuBtn {
  font-weight: bold;
  color: #fff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  transition: all 0.1s ease 0s !important;

}

.navbar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* General sidebar styles */
.bm-burger-button {
  display: none !important;
}

.bMenuBtn {
  margin-left: 10px;
}

.bMenuBurger {
  font-size: 28px !important;
  margin-bottom: 3px;
  cursor: pointer;
}

.bm-menu {
  background: var(--surface);
  padding: 2.5em 10px 0;
  font-size: 1em;
  line-height: 1.9em;

}

.hbSelectCont {
  margin-top: -1px;
  margin-left: -5px;
}

.hbMenuOption {
  font-weight: bold;
  margin: 22px !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #fff;
}

/* Wrapper for item list */
.bm-item-list {
  color: #000 !important;
  padding: 0.8em;
  text-transform: capitalize;
}

.bm-item-list a,
.hbMenuSelectIco {
  font-weight: bold;
  color: var(--on-primary) !important;
}

.bm-item-list a:hover {
  text-decoration: none;
  color: var(--primary) !important;
}

.bm-item-list svg,
.hbMenuSelectIco svg {
  font-size: 1.3em !important;
  display: inline-block;
  margin-top: -2px;
  margin-right: 10px;
}

.hbMenuSelectIco svg {
  margin-top: 10px;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-bottom: 17px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


.closeButton {
  color: var(--accent-light);
  font-size: 30px;
  cursor: pointer
}

.closeButton:hover {
  color: var(--accent-dark);

}


/* CHANNEL*/
.profHeaderDisplayName {
  color: var(--on-background);
}

.channelJumboCont {
  background-color: var(--surface);
}

.channelJumboConnectedHeight {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: cover !important;
  background-position: 50% !important;
  min-height: 50px !important;
}

.channelJumboContHeight {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: cover !important;
  background-position: 50% !important;
}

.channelJumboContHeight {
  min-height: 150px !important;

}

@media (min-width: 517px) {
  .channelJumboContHeight {
    min-height: 170px !important;

  }
}

@media (min-width: 615px) {
  .channelJumboContHeight {
    min-height: 190px !important;

  }
}

@media (min-width: 700px) {
  .channelJumboContHeight {
    min-height: 220px !important;

  }
}

@media (min-width: 800px) {
  .channelJumboContHeight {
    min-height: 240px !important;

  }
}

@media (min-width: 900px) {
  .channelJumboContHeight {
    min-height: 270px !important;

  }
}

@media (min-width: 1000px) {
  .channelJumboContHeight {
    min-height: 300px !important;

  }
}

.profileAvatarCont {
  margin-top: -63px !important;
  border: solid 3px #fff;
  border-radius: 50%;
  padding: 0% !important;
  position: relative;
}

.profileAvatarContConnected {
  padding: 0% !important;
  position: relative;
}

.border-active {
  border: solid 3px green;
  border-radius: 50%;
}

.imgChangeBtnCont {
  position: absolute;
  font-size: 26px;
  line-height: 0px;
  color: #fff;
  padding: 8px;
  background-color: var(--player-overlay-bg);
  border-radius: 50%;
  cursor: pointer
}

.avatarChangeBtnPos {
  bottom: 0px;
  right: 0px;
}

.profBgChangeBtnPos {
  bottom: 15px;
  right: 15px;
}

/* DISPLAY CENTER CIRCLE BUTTON*/
.fadeIn2sec {
  -webkit-animation: 3s ease 0s normal forwards 1 fadeinDelay2sec;
  animation: 2s ease 0s normal forwards 1 fadeinDelay2sec;
}

.displayCenterCircleBtnCont {
  z-index: 200;
}

.displayCenterCircleBtn {
  background-color: var(--player-overlay-bg);
  color: var(--template-primary);
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100px;
  height: 100px;
  padding-top: 35px;
  border-radius: 50%;
  border: solid 2px var(--template-primary);
  cursor: pointer;
  transition: 0.3s;
}

.displayCenterCircleBtn:hover {
  border: solid 2px rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
  background-color: var(--template-primary);
}

.displayCenterCircleCameraIco {
  font-size: 30px;
}

.displayCenterCircleText {
  color: var(--template-primary);
  font-size: 13px;
  font-weight: bold;
  font-weight: bold;
  text-transform: uppercase;
}

@keyframes fadeinDelay2sec {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.streamDescCont h4 {
  word-break: break-word;
  font-weight: 500 !important;
}

/* PLAYERS COMMON*/
.fullScreenBtnsCont {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 100;
  font-size: 26px;
  cursor: pointer;
}

.playerMicrophoneBtnWrapper{
  position: absolute;
  left: 20px;
  top: 80px;
  z-index: 100;
  font-size: 24px;
  cursor: pointer;

}
.controlbarCornerSideCont {
  position: absolute;
  width: 50px;
  top: 0px;
  z-index: 100;
  right: 0px;
  background: var(--player-overlay-bg);
  border-bottom-left-radius: 15px;
}

.controlbarCornerTopCont {
  position: absolute;
  width: auto;
  top: 0px;
  z-index: 100;
  right: 50px;
  padding-bottom: 3px;
  background: var(--player-overlay-bg);
  border-bottom-left-radius: 15px;
}

.stopStreamBtn {
  background-color: var(--accent-dark);
  text-align: center;
  cursor: pointer;
  transition: 0.3s;

}

.stopStreamBtn:hover {
  background-color: var(--accent-light);
}

.controlbarCornerSideCont .stopStreamBtn {
  width: 22px;
  height: 22px;
  border-radius: 3px;

}

.controlbarCornerSideCont .stopStreamBtn svg {
  margin-top: -5px;
}

.startStreamBtn {
  background-color: var(--online-green);

  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}

.controlbarCornerSideCont .startStreamBtn {
  width: 22px;
  height: 22px;
  border-radius: 3px;

}

.controlbarCornerSideCont .startStreamBtn svg {
  margin-top: -5px;
}

.controlbarVerticalMenuCont {
  position: absolute;
  width: 50px;
  top: 0px;
  z-index: 100;
  right: 0px;
  background: var(--player-overlay-bg);
  border-bottom-left-radius: 15px;
}

.controlbarVerticalMenuCont {
  position: absolute;
  width: 50px;
  top: 0px;
  z-index: 100;
  right: 0px;
  background: var(--player-overlay-bg);
  border-bottom-left-radius: 15px;
}

.controlbarTopOverMenuCont {
  position: absolute;
  width: auto;
  top: 0px;
  z-index: 100;
  right: 49px;
  padding-bottom: 3px;
  background: var(--player-overlay-bg);
  border-bottom-left-radius: 15px;

}

.controllBarCont {
  background-color: var(--black);
  color: var(--on-surface)
}


.controlBarBtn {
  font-size: 21px;
  cursor: pointer;
  color: var(--on-surface)
}

.controlBarBtn.disabled {
  color: var(--on-surface-disabled);
  cursor: default;
}

.controlBarBtnMob {
  font-size: 20px;
  cursor: pointer;
  color: #fff;
}

.controlBarBtnMob.disabled {
  color: var(--on-surface-disabled);
  cursor: default;
}

/* AUDIENCE PLAYER */

.animatedTipCoin {
  position: absolute;
  bottom: 5%;
  left: 50%;
  z-index: 200;
  color: var(--yello-gold);
  font-size: 36px;

  animation: tipCoinMove 1s infinite linear;
}

@keyframes tipCoinMove {
  from {
    transform: scale(1) rotate3d(0) translateX(0px) translateX(0px);
    opacity: 1;
  }

  50% {
    transform: scale(0.75) rotate3d(1, 1, 1, 360deg) translateY(-120px) translateX(-120px);
    opacity: 0.25;

  }

  to {
    transform: scale(0) rotate3d(1, 1, 1, 480deg) translateY(-220px) translateX(200px);
    opacity: 0;

  }
}

@keyframes tipCoinRotation {
  from {
    transform: rotate3d(0);
  }

  to {
    transform: rotate3d(1, 1, 1, 360deg);
  }
}

.audienceJoinBtnsCont {
  position: absolute;
  z-index: 100;
  height: auto;
  right: 0px;
  left: 0px;
  top: 0px;
  color: #fff;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(226, 21, 153, 0) 83%);
}

.audienceJoinChannelInfoCont:hover {
  color: var(--yello-gold) !important;
}

.audienceJoinChannelInfoCont:hover .broadcastControlBtn {
  color: var(--yello-gold) !important;
}

.audienceJoinBtnsContent {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 200;
}

.audienceConnStatusTxtOffline {
  text-transform: uppercase;
  font-weight: 700 !important;
  color: rgba(255, 255, 255, 0.6);
}

.audienceConnStatusTxtOnline {
  text-transform: uppercase;
  font-weight: 700 !important;
  color: var(--yello-gold);
}

.sendTipMenuSideCont {
  position: absolute;
  right: 50px;
  top: 43px;
  max-height: 100%;
  overflow: auto;

  background: var(--player-overlay-bg);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: 0.2s;
  z-index: 100;

}

.sideBarBtnCont {
  transition: 0.2s;
}

.sideBarBtnCont.active {
  border-radius: 10px;
}

.giveTipSideBarActionBtn {
  position: relative;
  font-weight: 600;
  font-size: 15px;
  padding: 3px 15px;
  color: var(--on-primary);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0.4rem;
  border: solid 1px var(--button-border);
  text-transform: uppercase;

}

.giveTipSideBarActionBtn:before {
  background: rgba(0, 0, 0, 0.0);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.giveTipSideBarActionBtn:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.giveTipSideBarActionBtn:active {
  background-color: rgba(255, 255, 255, 0.6);
}

.giveTipSideBarActionBtn svg {
  color: var(--yello-gold);
}

.sendTipMenuBottomCont {
  position: absolute;
  background-color: var(--player-overlay-bg);
  left: 0px;
  right: 0px;
  top: -31px;
  height: auto;
  z-index: 500;
}

.giveTipBottomBarMenuBtn {
  font-weight: 600;
  padding: 5px 25px;
  color: var(--on-primary);
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0.4rem;
  border: solid 1px var(--button-border);
  text-transform: uppercase;
}

.giveTipBottomBarMenuBtn:hover {
  background-color: var(--surface)
}

.giveTipBottomBarMenuBtn svg {
  font-size: 22px;
  margin-top: -5px;
  color: var(--yello-gold);
}

.giveTipBottomBarActionBtn {
  font-weight: 600;
  font-size: 15px;
  padding: 3px 15px;
  color: var(--on-primary);
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0.4rem;
  border: solid 1px var(--button-border);
  text-transform: uppercase;
}

.giveTipBottomBarActionBtn:hover {
  background-color: var(--surface);
  background-color: rgba(255, 255, 255, 0.1);
}

.giveTipBottomBarActionBtn svg {
  font-size: 18px;
  margin-top: -3px;
  color: var(--yello-gold);
}



/* STREAMER PLAYER */
.onlineStatusCont {
  position: absolute;
  left: 15px;
  top: 15px;
  padding: 0px 8px;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  border-radius: 8px;
}
.cameraSettingsInnerWrapper{
  position:absolute;
   position: absolute;
  left: 10px;
  top: 45px;
  padding: 0px 8px;
  font-size: 14px;
  z-index: 100;
  border-radius: 50%;
  cursor:pointer;
}
.cameraSettingsIcoCamera{
  font-size: 35px;
}
.camSettingsIcoGear{
  position: absolute;
  z-index: 100;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  top:8px;
  left:12px;
  border:solid 1px rgba(0, 0, 0, 0);
}
.onlineDot {
  background-color: rgba(0, 255, 64, 0.4);
  float: left;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 6px;
  margin-right: 5px;
}

.presenceCountCont {
  position: absolute;
  background-color: var(--player-overlay-bg);
  top: 0px;
  right: 0px;
  padding: 10px 10px;
  z-index: 200;

}

.presenceCountVerticalCont {
  position: absolute;
  background-color: var(--player-overlay-bg);
  top: 0px;
  right: 0px;
  padding: 10px 10px 13px 10px;
  font-weight: bold;
  z-index: 200;
}

.broadcastIco {
  display: inline-block;
  position: relative;
  background-color: var(--red);
  color: #fff;
  line-height: 0% !important;
  font-size: 14px;
  padding: 0px;
  height: 19px;
  width: 19px;
  top: -3px;
  border-radius: 3px;
}

.broadcastIcoSvg {
  margin-left: 2px;
  margin-top: 2px;
  line-height: 0px;

}

.broadcastControlBtn {
  display: inline-block;
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  width: auto;
  transition: 0.2s;
  color: var(--on-background)
}

.broadcastControlBtn:hover {
  color: var(--yello-gold)
}

.broadcastControlBtnIco {
  display: inline-block;
  position: relative;
  background-color: var(--red);
  color: #fff;
  line-height: 0% !important;
  font-size: 14px;
  padding: 0px;
  height: 19px;
  width: 19px;
  top: -4px;
  margin-left: 2px;
  margin-right: 5px;
  border-radius: 3px;
}

.broadcastControlBtnIco.connecting {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.broadcastControlBtnIco.online {
  background-color: var(--online-green);
}

.broadcastControlBtnIcoSvg {
  margin-left: 2px;
  margin-top: 2px;
  line-height: 0px;

}


.playerCoinsCont {
  -webkit-animation: goldGlow 1s ease-in-out infinite alternate;
  -moz-animation: goldGlow 1s ease-in-out infinite alternate;
  animation: goldGlow 1s ease-in-out infinite alternate;
}

@keyframes goldGlow {
  from {
    text-shadow: 0 0 1px #fff, 0 0 4px rgb(255, 218, 135), 0 0 8px rgb(255, 218, 135);
  }

  to {
    text-shadow: 0 0 6px #fff, 0 0 10px rgb(245, 189, 68), 0 0 15px rgb(245, 189, 68);
  }
}

.playerCoinsIco {
  font-size: 21px;
  margin-top: -4px;
  color: var(--yello-gold);
}


.playerCont {
  background-color: var(--surface);
  min-height: 200px;
}


.connectionStateInfo {
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 10px;
  line-height: 0%;
  font-weight: bold;
}

.connectionStateInfo.online {
  color: var(--online-green)
}

.connectionStateInfo.offline {
  color: var(--online-red)
}


.streamPosterCont {
  position: relative;
  border: solid 1px rgba(255, 255, 255, 0.2);
  overflow: hidden;

}

.disabledCameraNoPosterWraper {
  position: relative;
  max-width: 100%;

  display: block;
  border: solid 1px rgba(255, 255, 255, 0.2);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 80%, black 100%);
  overflow: hidden;

}

.noPosterBgImg {
  width: 100%;
  height: auto;
  opacity: 0;
}

.transp50 {
  opacity: 0.5;
}

.disabledCameraNoPosterWraper.audience {
  background-color: #1d1d1d;
}

.disabledCameraNoPosterCameraaBtnWrapper {
  position: absolute;
  bottom: 100px;
  width: 100%;


}

.disabledCameraPosterImg {
  max-width: 100%;
  max-height: calc(100vh - 100px);
  transform: scaleX(-1);
  display: block;
}

.qualityModalCameraOffBtn {
  color: var(--warningRed);
  font-weight: bold;
  cursor: pointer;
}

.qualityModalCameraOffBtn:hover {
  color: var(--warningRedHover)
}

/* CHAT*/
.chatContentBtn.active {
  color: var(--yello-gold)
}

.chatSendBtn {
  font-size: 30px;
  margin-top: 3px;
  color: var(--template-primary)
}

.fontShrinkeBtn {
  position: relative;
  width: 30px;
  float: right;
  cursor: pointer
}

.fontEnlargeBtn {
  position: relative;
  width: 30px;
  float: right;
  cursor: pointer
}

.chatOverCont {
  position: absolute;

  z-index: 500;
}

.chatOverCont.fullWidth {
  xxbottom: 0px;
  top:250px;
  left: 0px;
  right: 0px;
  height: 50%;
  min-height:50vh;
  border-top: solid 1px rgba(255, 255, 255, 0.3);
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
}

.chatOverCont.side {
  bottom: 0px;
  left: 0px;
  top: 50px;
  width: 50%;
  border: solid 1px rgba(255, 255, 255, 0.3);

}

.chatFloatingCont {
  position: absolute;
  top: 30vh;
  left: 80px;
  width: 16vw;
  min-width: 350px;
  height: 70vh;
  z-index: 500;
  resize: both;
  border: solid 1px var(--modal-border);
}

@media (min-width: 1000px) {
  .chatFloatingCont {
    position: absolute;
    top: 30vh;
    right: 80px;
    width: 16vw;
    min-width: 350px;
    height: 45vh;
    z-index: 500;
    resize: both;
    border: solid 1px var(--modal-border);
  }
}

.chatFloatingCont.hidden {
  height: auto !important;
}

.chatFloatingContHeader {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 0px;
  height: 35px;
  padding: 5px 5px 5px 10px;
  left: 0px;
  right: 0px;
  background-color: var(--background);
  text-transform: uppercase;
  font-weight: 500;
}

.chatFloatingInnerCont {
  position: absolute;
  top: 35px;
  bottom: 40px;
  left: 0px;
  right: 0px;
  background-color: var(--player-overlay-bg);
  ;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 14px;
}

.chatMessageCont {}

.chatMessageCont svg {
  font-size: 18px;
}

.chatNotsCont {
  padding-left: 20px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.chatNotsCont svg {
  font-size: 18px;
  margin-top: -3px;
}

.chatMsgDisplayName {
  font-weight: 700;
  text-transform: capitalize;
  color: var(--on-surface-faded);
}

.chatMsgDisplayName.isHost {
  color: var(--yello-gold);
}

.chatNotsDisplayName {
  font-weight: 700;
  text-transform: capitalize;
  font-style: italic;
  padding-left: 20px;
  color: var(--on-surface-faded);
}

.chatNotsTxt {
  color: var(--yello-gold);
  font-weight: 600;
}



.chatOverToggleBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
  z-index: 200;
  cursor: pointer;
  font-size: 23px;
  line-height: 0% !important;
}

.chatOverTogglePlusBtn {
  position: relative;
  z-index: 200;
  cursor: pointer;
  font-size: 23px;
  top: 0px;
}

.chatOverToggleText {
  font-size: 16px;
  font-weight: 600;
}

.chatLastMessagePrevievCont {
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  white-space: nowrap;
  padding-left: 10px;
  margin-right: 10px;
}



.chatOverInnerCont {
  position: absolute;
  top: 35px;
  bottom: 40px;
  left: 0px;
  right: 0px;
  background-color: var(--player-overlay-bg);
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 14px;
}

.chatOverContHeader {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 0px;
  min-height: 35px;
  padding: 5px 5px 5px 10px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  font-weight: 500;
}

.chatOverHiddenCont {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--player-overlay-bg);
  z-index: 200;

}

.chatMdCont {
  position: relative;
  min-height:50vh;
  border-left: solid 1px var(--modal-border);
}

.chatMdContHeader {
  z-index: 100;
  position: absolute;
  top: 0px;
  height: 35px;
  padding: 5px 5px 5px 10px;
  left: 0px;
  right: 0px;
  background-color: var(--surface);
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: solid 1px var(--modal-border);
}

.chatMdInnerCont {
  position: absolute;
  top: 35px;
  bottom: 40px;
  left: 0px;
  right: 0px;
  background-color: var(--surface);
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 14px;
}

.chatMdInputCont {
  position: absolute;
  bottom: 0px;
  height: 40px;
  left: 0px;
  right: 0px;
  padding: 5px 8px 0px 8px;
  ;
  background-color: var(--surface);
}

.chatMdInputCont input {
  margin-bottom: 0px !important;
  border: solid 1px rgba(255, 255, 255, 0.3) !important;
  height: auto !important;
}

.chatAvatar.isHost {
  border: solid 1px var(--yello-gold);
}


.chatMsgMsg {}

.chatMsgMsg.isHost {}



.fadeAndHide {

  -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 4s linear forwards;
  opacity: 0;
  height: 46px !important;
}

@keyframes fadeinout {
  0% {
    opacity: 1;
    height: 46px !important;
  }

  50% {
    opacity: 1;
  }

  99% {
    opacity: 0;
    height: 0px !important;
  }

  100% {
    height: 0px !important;

  }
}

/* BELLOW TABS */
.bellowTabsBtn {
  cursor: pointer;
  font-size: 26px;
  color: var(--on-surface-faded)
}

.bellowTabsBtn.active {
  border-bottom: solid 2px var(--accent-regular);
  color: var(--on-surface)
}

/* SNAPSHOT CAPTURE */
.snapshotFlash {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 1000;
}

.snapshotTimerCont {
  line-height: 0%;
  position: absolute;
  z-index: 100;
  top: 45px;
  right: 55px;
  height: 100px;
  width: 100px;
  padding-top: 45px;
  padding-left: 25px;
  font-size: 80px !important;
  font-weight: 900;
  color: #fff;
  background-color: var(--accent-light);
  border-radius: 10%;

}

.snapshotTimerRotateWarning {
  position: absolute;
  z-index: 100;
  top: 160px;
  text-align: center;
  background-color: var(--accent-light);
  padding: 15px 5px;
}

.redTransWarning {
  position: absolute;
  z-index: 100;
  top: 160px;
  text-align: center;
  background-color: var(--accent-light);
  padding: 15px 5px;
  border: solid 1px rgba(255, 255, 255, 0.8);
  border-radius: 4px;
}

.snapshotPreviewImg {
  width: 100%;
  height: auto;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.snapshotDialogPreviewImg {
  max-width: 100%;


  display: block;
}

.snapShotDialogBtnCont {
  position: absolute;
  z-index: 100;
  right: 0px;
  left: 0px;
  top: 0px;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
}

.snapShotDialogCloseBtn {
  position: absolute;
  z-index: 100;
  top: 15px;
  right: 15px;
  font-size: 16px;
  line-height: 0px;
  background-color: rgba(255, 255, 255, 0.7);
  ;
  color: rgba(0, 0, 0, 0.6);
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;

}

.snapshotMenuTitleCont {
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
  text-transform: uppercase;

}

.snapshotMenuBtn {
  font-size: 48px;
  cursor: pointer;
  opacity: 0.85;


}

.snapshotMenuBtn:hover {
  opacity: 1;

}

/* BUTTONS*/

.xBtn {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 4px 20px;
  border-radius: 5px;
  cursor: pointer
}

.xBtn.xBtnText {
  color: rgba(225, 225, 225, 1);
  transition: 0.1s;
}

.xBtn.xBtnText:hover {
  color: var(--yello-gold);
}

.xBtn.xBtnPrimary {
  background-color: var(--background);
  color: rgba(225, 225, 225, 1);
  border: solid 1px rgb(54, 54, 54);
  transition: 0.1s;
}

.xBtn.xBtnPrimary:hover {
  background-color: var(--surface);

  border: solid 1px rgb(77, 77, 77);
}

/* BOOTSTRAP MODAL*/

.modal-header {
  padding: 10px !important;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-border-width) solid var(--modal-border) !important;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--on-modal-bg) !important;
  pointer-events: auto;
  background-color: var(--modal-bg) !important;
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--modal-border) !important;
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  overflow: hidden;
}

.modalNavBtn {}

.modalNavBtn>svg {
  margin-left: -0.35em;
  margin-right: 0.35em;
  margin-top: -3px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--form-control-color) !important;
  background-color: var(--form-control-bg) !important;
  background-clip: padding-box;
  border: 1px solid var(--modal-border) !important;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;

}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: .45 !important;
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--form-control-color) !important;
  background-color: var(--form-control-bg) !important;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid var(--modal-border) !important;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none;
}

.form-select:disabled {
  opacity: 0.3 !important;
}

/* OTHERS*/
.yelloGold {
  color: var(--yello-gold)
}

.redDimed {
  color: var(--red-dimmed);
}

.redDimedFakeLink {
  cursor: pointer;
  color: var(--red-dimmed);
}

.redDimedFakeLink:hover {
  color: var(--red-dimmed);
}

.warningRed {
  color: var(--warningRed)!important;
}

.faded {
  color: var(--on-surface-faded) !important
}

.capitalize {
  text-transform: capitalize;
}

.cursor {
  cursor: pointer;
}

.noCursor {
  cursor: default !important;
}

.fakeLink {
  cursor: pointer;
}

.fakeLink:hover {
  text-decoration: underline;
}

.fsAbsoluteOverlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 200;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.absolute-right-bottom {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.bold {
  font-weight: bold;
}

.upperCase {
  text-transform: uppercase !important;
}

.opacity07 {
  opacity: 0.7 !important;
}

.fontLight {
  font-weight: 300 !important;
}

.fontMedium {
  font-weight: 500 !important;
}

.small {
  font-size: 0.8em !important;
}

.bgSurface {
  background-color: var(--surface) !important;
}

.bgSurfaceFront {
  background-color: var(--surface-front) !important;
}

.modalBorder {
  border: 1px solid var(--modal-border) !important;
}
.multiCamBorder {
  border: 1px solid rgba(255,255,255,0.5)!important;
}
.containerBorder {
  border: 1px solid var(--container-border) !important;
}

.borderRounded {
  border-radius: 30px;
}

.yellorGold {
  color: var(--yello-gold);
}

.thin-border-bottom {
  border-bottom: solid 1px var(--modal-border);
}

.boadcastOnscreenBtns {
  position: absolute;
  z-index: 100;
  top: 3px;
  left: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 15px;

}

.homeSwiperMenuWrapper {
  border-bottom: solid 1px var(--modal-border);
}

.homeSwiperMenuWrapper .swiper-button-next,
.homeSwiperMenuWrapper .swiper-button-prev {
  color: rgba(225, 225, 225, 0.4);
  height: 10px;
}

.homeSwiperMenuWrapper .swiper-button-next.swiper-button-disabled,
.homeSwiperMenuWrapper .swiper-button-prev.swiper-button-disabled {
  color: rgba(225, 225, 225, 0);
}

.homeSwiperMenuWrapper .swiper-button-prev {
  left: 0px;
}

.homeSwiperMenuWrapper .swiper-button-next {
  right: 0px;
}

.homeMenuSwiperItem {
  width: 150px;

  padding: 15px
}

.homeMenuSwiperItem img {
  width: 40px;
}

.goalCont {
  position: absolute;
  z-index: 50;

  min-width: 250px;
  max-width: 90%;
  background-color: rgba(0, 0, 0, 0.3);

}


.goalCont.topLeft {
  top: 50px;
  left: 15px;
}

.goalCont.bottomRight {
  bottom: 10px;
  right: 15px;
}

.goalCont .goalAmountCont {
  padding-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  position: relative;
  min-width: 150px;
  max-width: 100%;
  text-shadow: 2px 3px 0px rgba(0, 0, 0, 1);
}

.goalCont .goalProgressCont {
  position: relative;
  min-width: 100%;

  background-color: rgba(0, 0, 0, 1);
  margin-top: 2px;
  color: #fff;
  border: solid 2px var(--yello-gold);

}


.goalCont .goalReachedCont {
  position: relative;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 0px;
  font-size: 14px;
  font-weight: bold;
  z-index: 100;
  color: var(--yello-gold);
  text-align: center;


}

.goalReachedTextCont {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2px;
  z-index: 100;
}

.goalCont .goalProgress {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: -0px;
  background-color: var(--accent-regular);
  background-color: rgb(216, 148, 0);
}

.goalSuccessTextCont {
  position: relative;
  width: 100%;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  border-bottom: 1px solid var(--yello-gold);
}
.goalSuccessTextCont svg{
  margin-top: -2px;
}
@media (min-width: 615px) {
  .goalCont {
    position: absolute;
    z-index: 50;

    min-width: 250px;
    max-width: 90%;
    background-color: rgba(0, 0, 0, 0.3);

  }

  .goalCont.bottomLeft {
    bottom: 10px;
    left: 15px;
  }

  .goalCont.topLeft {
    top: 50px;
    left: 15px;
  }

  .goalCont.bottomRight {
    bottom: 10px;
    right: 15px;
  }

  .goalCont .goalAmountCont {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    position: relative;
    min-width: 150px;
    max-width: 100%;
    text-shadow: 2px 3px 0px rgba(0, 0, 0, 1);
  }

  .goalCont .goalProgressCont {
    position: relative;
    min-width: 100%;

    background-color: rgba(0, 0, 0, 1);
    margin-top: 5px;
    color: #fff;
    border: solid 2px var(--yello-gold);

  }


  .goalCont .goalReachedCont {
    position: relative;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 0px;
    font-size: 21px;
    font-weight: bold;
    z-index: 100;
    color: var(--yello-gold);
    text-align: center;


  }

  .goalReachedTextCont {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 2px;
    z-index: 100;
  }

  .goalCont .goalProgress {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: -0px;
    background-color: var(--accent-regular);
    background-color: rgb(216, 148, 0);
  }

  .goalSuccessTextCont {
    position: relative;
    width: 100%;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    border-bottom: 1px solid var(--yello-gold);


  }
}

.rightContentCont{
  min-height: 50vh;
}

.form-switch .form-check-input {
  border-radius: 25px!important;
  background-color: #000!important;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28246, 190, 0, 10%29%27/%3e%3c/svg%3e')!important;
}
.form-switch .form-check-input:checked {
  background-color: #f6be00!important;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.80%29%27/%3e%3c/svg%3e')!important;

}

.privTransListWrapper .list-group-item-dark {
  color: #d3d3d4!important;
  background-color: #121416!important;
}
.privTransListWrapper .list-group-item-light {
  color: #d3d3d4!important; 
  background-color: #1e2125!important;
}

.privListEditBtn{
  font-size:26px;
}