/*Section Title*/
.section-title{
  position: relative;
  border-bottom: 1px solid rgba($white,0.1);
  padding-bottom: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  &:before{
    position:absolute;
    content: "";
    height: 3px;
    width: 100px;
    background-color: $primary;
    left: 0;
    bottom: -2px;
  }

  .title{
    color:$primary;
    font-weight: 500;
    margin-right: 15px;
  }

  .btn-link{
    margin-left: auto;
    text-decoration: none !important;
    transition: all 0.3s ease-in-out;
    color: $white;
    flex: none;
    &:hover{
      color: $primary;
    }
  }
}

.section-title.text-center{
  text-align: center;
  justify-content: center;
  &:before{
    left:50%;
    transform: translateX(-50%);
  }

  .title{
    margin-right: 0px;
  }
  
}

/* Share Box */
.share-box {
  position: relative;
  .share-box-social {
    text-align: center;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    bottom: 100%;
    display: inline-block;
    background: $primary;
    padding: 5px 10px;
    transition: all 0.3s ease-in-out;
    transform: translate(-50%, 0px);

    li {
      a {
        padding: 6px 0px;
        color: $white;
        font-size: 13px;
        line-height: 1;
        display: block;

        &:hover {
          color: $secondary;
        }
      }
    }

    &:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: $primary transparent transparent transparent;
      content: "";
      position: absolute;
      left: 50%;
      bottom: -6px;
      z-index: 99;
      transform: translate(-50%, 0px);
    }
  }

  &:hover {
    .share-box-social {
      opacity: 1;
      visibility: visible;
      margin-bottom: 6px;
    }
  }
    a{
      color: $white;
      &:hover{
        color: $primary;
      }
    }
}

.like {
  position: relative;
 &:before{
    content: '\f004';
    font-family: "Font Awesome 6 Free";
    font-weight: 400;
 }
}
.like.active {
 &:before{
    content: '\f004';
    font-family: "Font Awesome 6 Free";
    font-weight: 600;
    color: $primary;
 }
}

.add-icon {
  position: relative;
 &:before{
    content: '\2b';
    font-family: "Font Awesome 6 Free";
    font-weight: 600;
    font-size: 16px;
 }
}
.add-icon.active {
 &:before{
    content: '\f00c';
    font-family: "Font Awesome 6 Free";
    font-weight: 600;
    color: $primary;
 }
}


.bookmark {
  position: relative;
 &:before{
    content: '\f02e';
    font-family: "Font Awesome 6 Free";
    font-weight: 400;
 }
}
.bookmark.active {
 &:before{
    content: '\f02e';
    font-family: "Font Awesome 6 Free";
    font-weight: 600;
    color: $primary;
 }
}


/* Form */
.form-control {
  padding: 14px 20px;
  height: 50px;
  font-size: 16px;
  font-family: $font-hedding;
  border-color: $secondary;
  transition: all 0.3s ease-in-out;
  color: $body-color;
  background-color: $secondary;
 border-radius: inherit !important;
  &:focus {
    color: $body-color;
    box-shadow: none;
    border-color: $secondary;
    background-color: $secondary;
  }
}

.form-check{
  .form-check-input{
    border:1px solid $primary;
    background-color:transparent;
    border-radius: inherit;
    outline: none;
    &:focus{
      box-shadow: none;
    }
  }
  .form-check-input:checked{
    border:1px solid $primary;
    background-color: $primary;
    box-shadow: none;
  }
}

/*Search*/
.search {
  ul {
    li {
      a {
        color: $white;
        display: flex;
        text-decoration: none;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: $dark;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 9999;
}

#search.open {
  opacity: 1;
  visibility:visible ;
}

#search input[type="search"] {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 50%;
  color: $white;
  background: $secondary;
  font-size: 18px;
  height: 60px;
  font-weight: 300;
  text-align: center;
  border: $border-color;
  margin: 0px auto;
  margin-top: -51px;
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  text-transform: capitalize;
}

#search .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 40px;
  margin-left: -45px;
}

#search .close {
  position: absolute;
  z-index: 9;
  top: 15px;
  right: 15px;
  background-color: $secondary;
  color: $white;
  opacity: 1;
  display: inline-block;
  height: 50px;
  width: 50px;
  font-size: 18px;
  border:transparent;
  transition: all 0.3s ease-in-out;
  &:hover{
    background-color:$primary;
  }
  i{
    position: relative;
    z-index: -1;
  }

}

.social-icon{
  ul{
    padding: 0;
    margin: 0;
    display: flex;
    li{
        margin-right: 15px;
        display: inline-block;
      a{
        color: $white;
        font-size: 14px;
        line-height: 1;
        width: 30px;
        height: 30px;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius-md;
        &:hover{
          background-color: $white;
          color: $primary;
        }
      }
        &:last-child{
          margin-right: 0;
       }
    }
  }
}


/*Callout*/
.callout{
  background-color: $dark;
  padding: 80px 50px;
  text-align: center;
  h4{
    margin-bottom: 15px;
    font-weight: 500;
  }
  p{
    margin-bottom: 25px;
  }
}

/* Back To Top */
.back-to-top {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 50px;
    right: 40px;
    width: 40px;
    height: 40px;
    margin: 0px;
    color: $white;
    font-size: 16px;
    background: $primary;
    transition: all 0.3s ease-in-out;
    z-index: 999;
    &:hover {
      background: $white;
      color: $primary;
    }
    &:focus {
      background: $white;
      color: $primary;
    }
  }
}

/*inner-banner*/
.inner-banner{
  .header-inner-title{
    padding:160px 0px;
    text-align: center;
    .title{
      text-transform: uppercase;
    }
  }
}

/* List */
ul.list {
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 15px;
    display: flex;
    align-items: start;

    i{
      margin-right: 10px;
      color: $white;
      background-color: $primary;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      margin-top: 5px;
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

}

ul.list.list-circle {
  li {
    i{
      font-size: 6px;
    }
  }
}

/*About Section*/
.about-section{
  display: flex;
  align-items: end;
  background-color: $dark;
  padding: 100px 50px 70px 70px;
  margin-top: 80px;
  .about-img{
    flex: 0 0 440px;
    position: relative;
    margin-top: -160px;
  }

  .about-info{
    padding-left: 50px;
    .about-title{
      margin-bottom: 20px;
      font-weight: 500;
    }
    .about-content{
      margin-bottom: 30px;
      .counter.counter-02 {
        padding-right: 30px;
        border-right: 1px solid $primary;
        display: inline-block;
        margin-right: 30px;
      }

    }
    .about-counter{
      margin-bottom: 30px;
      display: flex;
      .counter.counter-02{
        border-right: 1px solid $primary;
        padding-right:30px ;
        margin-right:30px ;
      }
    }
  }
}

/*Map*/
.map{
  height: 685px;
    iframe{
    filter: grayscale(100%) invert(100%) contrast(100%);
  }
}

/*Contact Form*/
.contact-section{
  position: relative;
  .contact-form{
    position: absolute;
    top: 50px;
    width: 570px;
    background-color: $dark;
    padding: 50px;
    z-index: 1;
    form{
      .form-control{
        margin-bottom: 20px;
      }
    }
  }
}

/*Contact Info*/
.contact-info{
  padding: 0 30px;
  text-align: center;
  .contact-info-icon{
    display: flex;
    flex: 0 0 80px;
    height: 80px;
    width: 80px;
    background-color: $primary;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    i{
      font-size: 36px;
      color: $white;
    }
  }
  .contact-info-title{
    margin-top: 22px;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  p{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  }

  .contact-detail{
    >span{
      display: inline-block;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      margin-bottom: 10px;
    }
    .mail{
      margin-bottom: 0px;
    }
  }
}

/*login*/
.login-tabs{
  padding: 50px;
}
.login-social-media{
  padding: 40px 30px;
}

/*Error-404*/
.error-404{
  h2{
    font-size: 42px;
    line-height: 50px;
    color: $white;
    margin-bottom: 25px;
    margin-top: 40px;
  }
  p{
    font-size: 18px;
    line-height: 28px;
    color: $white;
    margin-bottom: 35px;
  }
}


@media (max-width:1199px) {

  .about-section{
    padding: 50px 30px 50px 50px;
    margin-top:0px;
    .about-img{
      flex: 0 0 370px;
      margin-top: 0px;
    }
  }
}


@media (max-width:991px) {



  .about-section{
    align-items:start;
    padding: 30px;
    .about-img{
      flex: 0 0 270px;
    }
    .about-info{
      padding-left: 30px;
    }
  }

  .contact-info{
    padding: 0px;
  }
}

@media (max-width:767px) {
  .callout{
    padding: 60px 30px;
  }

  .about-section{
    display: block;
    .about-img{
      flex: 0 0 100%;
      margin-bottom: 30px;
    }
    .about-info{
      padding-left: 0px;
    }
  }

  .inner-banner{
    .header-inner-title{
      padding: 100px 0px;
    }
  }

  .contact-section {
    .contact-form{
      position: inherit;
      top:0;
      width: 100%;
      padding: 30px;
    }
  }

  .map{
    height: 450px;
  }
}
@media (max-width:575px) {

  .callout{
    padding: 40px 20px;
  }

  .about-section{
    padding: 30px 15px;
    .about-info{
      padding-left: 0px;
      .about-counter{
        .counter.counter-02{
          padding-right: 20px;
          margin-right: 20px;
        }
      }
    }
  }

  .inner-banner{
    .header-inner-title{
      padding: 70px 0px;
    }
  }

  .map{
    height: 300px;
  }

  .login-tabs{
    padding: 20px;
  }

  .login-social-media {
    padding: 20px 15px;
  }
}
