/*****************************
	Pie Chart
*****************************/
	.pie-chart.pie-chart-percentage {
		.round-chart {
			display: flex;
			position: relative;
			text-align: center;
			span {
				display: inline-block;
				font-size: 28px;
				color: #ffffff;
				z-index: 2;
				font-weight: 600;
				position: absolute;
			}
			.percent {
				&:after {
					content: "%";
				}
			}
		}
		.chart-title {
			font-size: 22px;
			font-weight: 600;
			margin-left:15px;
		}
	}