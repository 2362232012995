/*****************************
  Owl carousel
*****************************/
.owl-carousel {
	.owl-item {
		img {
			width: auto;
			display: inherit;
		}
	}

	.owl-dots{
		text-align: center;
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 25px;

		.owl-dot{
			span{
				height: 10px;
				width: 10px;
				background-color: $white;
				border-radius: $border-radius-md;
				margin-right: 5px;
				display: block;
			}
			&:last-child{
				margin-right: 0;
			}
			&:hover{
				span{
					background-color: $primary;
				}
			}
		}
		.owl-dot.active{
			span{
				background-color: $primary;
			}
		}
	}
}

.owl-carousel.owl-nav-center{
 .owl-nav{
 	  button{
  		width: 35px;
  		height: 35px;
  		background: $primary;
  		opacity: 0;
  		&:hover{
  			background: $white;
  			color: $primary;
  		}
 	   }
	    .owl-prev{
			position: absolute;
			top: 50%;
			left: 0px;
			transform: translateY(-50%);
			transition: all 0.3s ease-in-out;
		}
			.owl-next{
			position: absolute;
			top: 50%;
			right: 0px;
			transform: translateY(-50%);
			transition: all 0.3s ease-in-out;
		}
  }
  &:hover{
  	.owl-nav{
  		button{
  			opacity: 1;
  		}
  		.owl-prev{
  			left: 10px;
  		}
  		.owl-next{
  			right: 10px;
  		}
  	}
  }
}

.categories-slider{
	.owl-carousel.owl-nav-center {
		.owl-nav {
			.owl-next{
				right: 360px;
			}
		}
		&:hover{
			.owl-nav {
				.owl-next{
					right: 360px;
				}
			}
		}
	}
}

.owl-carousel.owl-nav-center.arrow-center{
	.owl-nav {
		button{
			background-color: inherit;
			font-size: 50px;
			width: inherit;
			height: inherit;
		}
		.owl-prev{
			left: -90px;
		}
		.owl-next{
			right: -90px;
		}
	}

	  &:hover{
  	.owl-nav{
  		.owl-prev{
  			left: -80px;
  		}
  		.owl-next{
  			right: -80px;
  		}
  	}
  }
}

.categories-slider.episode {
	.owl-carousel.owl-nav-center {
		.owl-nav {
			.owl-next {
				right: 280px;
			}
		}
	}
}

.categories-slider.episode {
	.owl-carousel.owl-nav-center {
		&:hover {
			.owl-nav {
				.owl-next {
					right: 290px;
				}
			}
		}
	}
}

@media (max-width:1600px) {
	.owl-carousel.owl-nav-center.arrow-center{
		.owl-nav {
			button{
				font-size: 30px;
			}
			.owl-prev{
				left: -40px;
			}
			.owl-next{
				right: -40px;
			}
		}

		  &:hover{
	  	.owl-nav{
	  		.owl-prev{
	  			left: -30px;
	  		}
	  		.owl-next{
	  			right: -30px;
	  		}
	  	}
	  }
	}
}

@media (max-width:1199px) {
	.owl-carousel.owl-nav-center.arrow-center{
		.owl-nav {
			button{
				font-size: 30px;
			}
			.owl-prev{
				left: -20px;
			}
			.owl-next{
				right: -20px;
			}
		}

		  &:hover{
	  	.owl-nav{
	  		.owl-prev{
	  			left: -15px;
	  		}
	  		.owl-next{
	  			right: -15px;
	  		}
	  	}
	  }
	}

	.categories-slider.episode {
		.owl-carousel.owl-nav-center {
			.owl-nav {
				.owl-next {
					right: 320px;
				}
			}
		}
	}

	.categories-slider.episode {
		.owl-carousel.owl-nav-center {
			&:hover {
				.owl-nav {
					.owl-next {
						right: 330px;
					}
				}
			}
		}
	}

}

@media (max-width:991px) {
	.categories-slider.episode {
		.owl-carousel.owl-nav-center {
				.owl-nav {
					.owl-next {
						right: 360px;
					}
				}
		}
	}
	.categories-slider.episode {
		.owl-carousel.owl-nav-center {
			&:hover {
				.owl-nav {
					.owl-next {
						right: 370px;
					}
				}
			}
		}
	}
}

@media (max-width:767px) {
	.owl-carousel {
		.owl-dots {
			margin-top: 20px;
		}
	}

	.owl-carousel.owl-nav-center {
		.owl-nav {
			.owl-prev {
				display: none;
			}
		}
	}

	.owl-carousel.owl-nav-center {
		.owl-nav {
			.owl-next {
				display: none;
			}
		}
	}
}

@media (max-width:479px) {
	.owl-carousel {
		.owl-dots {
			margin-top: 0px;
			bottom: 8px;
		}
	}
}
